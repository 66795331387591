module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"2235cc5f-5887-45ef-8239-f86a1a0e9bef","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijc1ZjU4Njg4LTM2NDQtNGFhNi1hNDZkLTJmNDdkZTBmYzE0OCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMjIzNWNjNWYtNTg4Ny00NWVmLTgyMzktZjg2YTFhMGU5YmVmIiwiaWF0IjoxNjU2MDAyODA1fQ.hCSq1wsAuVieo7DSrruj3G7TBOmDEeBCgPcWNAO1FsE"},"prd":{"siteId":"2235cc5f-5887-45ef-8239-f86a1a0e9bef","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijc1ZjU4Njg4LTM2NDQtNGFhNi1hNDZkLTJmNDdkZTBmYzE0OCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMjIzNWNjNWYtNTg4Ny00NWVmLTgyMzktZjg2YTFhMGU5YmVmIiwiaWF0IjoxNjU2MDAyODA1fQ.hCSq1wsAuVieo7DSrruj3G7TBOmDEeBCgPcWNAO1FsE"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"2235cc5f-5887-45ef-8239-f86a1a0e9bef","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijc1ZjU4Njg4LTM2NDQtNGFhNi1hNDZkLTJmNDdkZTBmYzE0OCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMjIzNWNjNWYtNTg4Ny00NWVmLTgyMzktZjg2YTFhMGU5YmVmIiwiaWF0IjoxNjU2MDAyODA1fQ.hCSq1wsAuVieo7DSrruj3G7TBOmDEeBCgPcWNAO1FsE"},"prd":{"siteId":"2235cc5f-5887-45ef-8239-f86a1a0e9bef","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijc1ZjU4Njg4LTM2NDQtNGFhNi1hNDZkLTJmNDdkZTBmYzE0OCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMjIzNWNjNWYtNTg4Ny00NWVmLTgyMzktZjg2YTFhMGU5YmVmIiwiaWF0IjoxNjU2MDAyODA1fQ.hCSq1wsAuVieo7DSrruj3G7TBOmDEeBCgPcWNAO1FsE"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WKKN7DW","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/instagram-scrappy/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"IGQVJWNVN6LXZAQTVBuT2VVazlncWlPbzVZASktWdDFGU3puNnFSVnBBX2NZANko4RzdlOF9iM2RPdUlkM3U3OUZAfOVhwZAWFNRFEwU3pBNnBZAQjVjXzJYbjM5a0ZA2SGdiWjAxMEtJSThYS2d1aW0tNGRhdgZDZD","limit":"15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1955122464712537"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SPANI ATACADISTA","short_name":"Spani","start_url":"/","background_color":"#15618C","theme_color":"#15618C","display":"minimal-ui","icon":"src/images/android-icon-192x192.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a9292a5115d10beed225b3a6f075455d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
