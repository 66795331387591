exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aplicativo-spani-js": () => import("./../../../src/pages/aplicativo-spani.js" /* webpackChunkName: "component---src-pages-aplicativo-spani-js" */),
  "component---src-pages-area-do-promotor-js": () => import("./../../../src/pages/area-do-promotor.js" /* webpackChunkName: "component---src-pages-area-do-promotor-js" */),
  "component---src-pages-atendimento-js": () => import("./../../../src/pages/atendimento.js" /* webpackChunkName: "component---src-pages-atendimento-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cartao-spani-js": () => import("./../../../src/pages/cartao-spani.js" /* webpackChunkName: "component---src-pages-cartao-spani-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-formas-de-pagamento-js": () => import("./../../../src/pages/formas-de-pagamento.js" /* webpackChunkName: "component---src-pages-formas-de-pagamento-js" */),
  "component---src-pages-grupo-zaragoza-spani-js": () => import("./../../../src/pages/grupo-zaragoza-spani.js" /* webpackChunkName: "component---src-pages-grupo-zaragoza-spani-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lgpd-js": () => import("./../../../src/pages/lgpd.js" /* webpackChunkName: "component---src-pages-lgpd-js" */),
  "component---src-pages-lojas-js": () => import("./../../../src/pages/lojas.js" /* webpackChunkName: "component---src-pages-lojas-js" */),
  "component---src-pages-ofertas-js": () => import("./../../../src/pages/ofertas.js" /* webpackChunkName: "component---src-pages-ofertas-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-templates-blog-categorias-blog-categorias-js": () => import("./../../../src/templates/blog-categorias/blog-categorias.js" /* webpackChunkName: "component---src-templates-blog-categorias-blog-categorias-js" */),
  "component---src-templates-ofertas-template-offer-js": () => import("./../../../src/templates/ofertas-template/offer.js" /* webpackChunkName: "component---src-templates-ofertas-template-offer-js" */),
  "component---src-templates-post-blog-post-blog-js": () => import("./../../../src/templates/post-blog/post-blog.js" /* webpackChunkName: "component---src-templates-post-blog-post-blog-js" */),
  "component---src-templates-spaniqualifica-template-cursos-js": () => import("./../../../src/templates/spaniqualifica-template/cursos.js" /* webpackChunkName: "component---src-templates-spaniqualifica-template-cursos-js" */),
  "component---src-templates-spaniqualifica-template-spani-qualifica-js": () => import("./../../../src/templates/spaniqualifica-template/spani-qualifica.js" /* webpackChunkName: "component---src-templates-spaniqualifica-template-spani-qualifica-js" */)
}

